import * as fromLimits from './limits.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as _ from 'lodash';

export const selectLimitsState = createFeatureSelector<fromLimits.State>(fromLimits.featureKey);

export const getLimits = createSelector(selectLimitsState, (state: fromLimits.State) => _.get(state, ['data']));

export const getLimitsError = createSelector(selectLimitsState, (state: fromLimits.State) => _.get(state, ['error']));

export const isLimitsLoading = createSelector(selectLimitsState, (state: fromLimits.State) => _.get(state, ['loading'], false));

export const isLimitsLoaded = createSelector(selectLimitsState, (state: fromLimits.State) => _.get(state, ['loaded'], false));
