import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import * as LimitsActions from './limits.actions';
import * as _ from 'lodash';

export interface Limits {
  [limitKey: string]: {
    max: number;
    current: number | null;
  };
}

export const featureKey = 'limits';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: Error | HttpErrorResponse | undefined;
  data: Limits;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: undefined,
  data: {}
};

export const reducer = createReducer(
  initialState,

  on(LimitsActions.loadLimits, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(LimitsActions.loadLimitsSuccess, (state, action) => ({
    ...state,
    error: undefined,
    loading: false,
    loaded: true,
    data: action.data
  })),

  on(LimitsActions.loadLimitsFailure, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loaded: true
  }))
);
